<template>
<div>
    <el-collapse>
        <el-collapse-item>
            <template slot="title">
                <i class="header-icon el-icon-s-operation"></i>筛选
                <span class="totalwrp">总条数:{{total}}</span>
            </template>
            <div>
                <el-row :gutter="8">
                    <el-col :span="7" class="searchwrp">
                        <label for="">进度 </label>
                        <el-select v-model="status" placeholder="请选择">
                            <el-option v-for="item in rateArr" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="7" class="searchwrp">
                        <label for="">搜索 </label>
                        <el-input type="text" v-model="keyword" clearable></el-input>
                    </el-col>
                    <el-col :span="10">
                        <label for="">下单时间 </label>
                        <el-date-picker v-model="dateGap" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setDate">
                        </el-date-picker>
                    </el-col>                    
                </el-row>
                <el-row>
                    <el-col :span="7" class="searchwrp">
                        <label for="">活动类别 </label>
                        <el-select v-model="activeType" placeholder="请选择">
                            <el-option v-for="item in activeArr" :key="item.Product_ID" :label="item.Product_Name" :value="item.Product_ID">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" round @click="searchKeyWord">筛选</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-collapse-item>
    </el-collapse>

    <el-table :data="orders" stripe tooltip-effect="dark" fit style="width: 100%" row-class-name="rows">
        <el-table-column label="单号" width="230" prop="Orders_Number">
            <template #default="scope">{{ scope.row.Orders_Number }}</template>
        </el-table-column>
        <el-table-column label="商家" width="220">
            <template #default="scope">{{ scope.row.Merchant_Name }}</template>
        </el-table-column>
        <!-- <el-table-column label="商品名" width="220">
        <template #default="scope">{{ scope.row.Merchant_Name }}</template>
      </el-table-column> -->
      <el-table-column label="微信昵称" width="160" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.Member_Name }}</template>
        </el-table-column>
        <el-table-column label="姓名" width="100">
            <template #default="scope">{{ scope.row.Orders_Receiver }}</template>
        </el-table-column>
        <el-table-column label="联系方式" width="170">
            <template #default="scope">{{ scope.row.Orders_Phone }}</template>
        </el-table-column>
        <el-table-column label="联系地址" width="220" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.Orders_Address }}</template>
        </el-table-column>
        <el-table-column label="状态	" width="100">
            <template #default="scope">{{
          switchStatus(scope.row.Orders_Status)
        }}</template>
        </el-table-column>
        <el-table-column label="下单时间" width="200">
            <template #default="scope">{{ scope.row.Orders_Created }}</template>
        </el-table-column>
        
        <el-table-column label="购买活动名" width="220" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.Orders_Detail[0].OD_Name }}</template>
        </el-table-column>
        <el-table-column label="购买数量" width="90" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.Orders_Detail[0].OD_Count }}</template>
        </el-table-column>
        <el-table-column label="订单总额" width="100">
            <template #default="scope">{{ scope.row.Orders_Total }}</template>
        </el-table-column>
        <el-table-column label="实付金额" width="100">
            <template #default="scope">{{ scope.row.Orders_Price }}</template>
        </el-table-column>
        <el-table-column label="备注" width="220" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.Orders_Remark }}</template>
        </el-table-column>
        <el-table-column label="操作" width="160" fixed="right">
            <template #default="scope">
                <el-button size="mini" type="primary" plain round @click="pullDetailsList(scope.row)">详情</el-button>
                <el-button size="mini" type="primary" plain round @click="editDetail(scope.$index)">修改</el-button>
                <!-- <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="再考虑"
            icon="el-icon-info"
            icon-color="red"
            title="确定要删除吗？"
            @confirm="deletOrder(scope.row)"
          >
            <el-button type="warning" size="mini" slot="reference" plain round
              >删 除</el-button
            >
          </el-popconfirm> -->
            </template>
        </el-table-column>
    </el-table>
    <el-dialog title="订单详情" v-model="detailFormVisible" :modal="false">
        <el-table :data="detailsList" stripe tooltip-effect="dark" fit style="width: 100%" row-class-name="rows">
            <!-- 图片	名称	规格	净含量	售价	数量	总价 -->
            <el-table-column label="图片" width="100">
                <template #default="scope">
                    <img style="width: 100%" :src="domin + scope.row.OD_Image" />
                </template>
            </el-table-column>
            <el-table-column label="名称" width="200">
                <template #default="scope">
                    {{ scope.row.OD_Name }}
                </template>
            </el-table-column>
            <el-table-column label="规格">
                <template #default="scope">
                    {{ scope.row.OD_Color }}
                </template>
            </el-table-column>
            <el-table-column label="净含量">
                <template #default="scope">
                    {{ scope.row.OD_Size }}
                </template>
            </el-table-column>
            <el-table-column label="售价">
                <template #default="scope">
                    {{ scope.row.OD_SellPrice }}
                </template>
            </el-table-column>
            <el-table-column label="数量">
                <template #default="scope">
                    {{ scope.row.OD_Count }}
                </template>
            </el-table-column>
            <el-table-column label="总价">
                <template #default="scope">
                    {{ scope.row.OD_TotalPrice }}
                </template>
            </el-table-column>
        </el-table>

        <el-row class="deliver-info">
            <el-col :span="12">
                <label>物流单位</label>
                <el-select v-model="deliverCompy" placeholder="请选择">
                    <el-option v-for="item in deliverCompys" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="12">
                <label>物流编号</label>
                <el-input type="text" v-model="deliverID"></el-input>
            </el-col>
        </el-row>
        <div class="dbtns">
            <el-button plain round @click="detailFormVisible = false">关闭</el-button>
            <el-button plain type="primary" round @click="sendDeliver">发货</el-button>
        </div>
    </el-dialog>
    <el-dialog title="修改" v-model="editDetailVisible"  :modal="false">
        <el-row class="edit-info">
            <el-col :span="12">
                <label>订单号</label>
                <el-input type="text" v-model="Orders_Number"></el-input>
            </el-col>
            <el-col :span="12">
                <label>接收人</label>
                <el-input type="text" v-model="Orders_Receiver"></el-input>
            </el-col>
            <el-col :span="12">
                <label>联系方式</label>
                <el-input type="text" v-model="Orders_Phone"></el-input>
            </el-col>
            <el-col :span="12">
                <label>地址</label>
                <el-input type="text" v-model="Orders_Address"></el-input>
            </el-col>
            <el-col :span="12">
                <label>运费</label>
                <el-input type="text" v-model="Orders_Freight"></el-input>
            </el-col>
            <el-col :span="12">
                <label>实付金额</label>
                <el-input type="text" v-model="Orders_Total"></el-input>
            </el-col>
            <el-col :span="12">
                <label>下单时间</label>
                <el-date-picker v-model="Orders_Created" type="date" placeholder="选择日期">
                </el-date-picker>
            </el-col>
            <el-col :span="12">
                <label>发货时间</label>
                <el-date-picker v-model="Orders_DeliveryTime" type="date" placeholder="选择日期">
                </el-date-picker>
            </el-col>
            <el-col :span="12">
                <label>接收时间</label>
                <el-date-picker v-model="Orders_ReceiveTime" type="date" placeholder="选择日期">
                </el-date-picker>
            </el-col>
            <el-col :span="24">
                <label>备注</label>
                <el-input v-model="Orders_Remark" type="textarea" :rows="6"></el-input>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="editDetailVisible = false">取 消</el-button>
            <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要修改吗？" @confirm="sureToEditDetail">
                <el-button type="primary" slot="reference">确定修改</el-button>
            </el-popconfirm>
        </div>
    </el-dialog>

    <el-pagination background layout="prev, pager, next" :total="total" @current-change="setPageIndx">
    </el-pagination>
</div>
</template>

<script>
let app = null
export default {
    data() {
        return {
            domin: "",
            Orders_Number: "",
            Orders_Receiver: "",
            Orders_Phone: "",
            Orders_Address: "",
            Orders_Freight: "",
            Orders_Total: "",
            Orders_Created: "",
            Orders_DeliveryTime: "",
            Orders_ReceiveTime: "",
            Orders_Remark: "",
            verify: "",
            starttime: "",
            endtime: "",
            activeType:"",
            activeArr:[],
            detailsList: [],
            deliverID: "",
            deliverCompy: "",
            deliverCompys: [
                "中通快递",
                "申通快递",
                "韵达快递",
                "京东快递",
                "顺丰快递",
                "非实物无物流",
            ],
            detailFormVisible: false,
            editDetailVisible: false,
            total: 0,
            Orders_ID: "",
            status: "",
            keyword: "",
            dateGap: "",
            orderIndx: 0,
            currentIndx: 0,
            remarkForm: {
                remark: "",
            },
            remarkFormVisible: false,
            rateArr: [{
                    label: "全部",
                    value: "",
                },
                {
                    label: "未处理",
                    value: "0",
                },
                {
                    label: "已完成",
                    value: "1",
                },
                {
                    label: "失败",
                    value: "2",
                },
            ],
            orders: [],
            pageindex: 1,
        };
    },
    created() {
       app = this.$.appContext.app
        this.domin = app.domin
        console.log(app.domin)
    },
    mounted() {
        this.pullList();
        this.pullActive()
    },
    methods: {
        setDate(val) {
            console.log("val:", val);
            if (!!val) {
                this.starttime = val[0];
                this.endtime = val[1];
            } else {
                this.starttime = this.endtime = "";
            }
        },
        searchKeyWord() {
            this.pageindex = 1;
            this.pullList();
        },

        editDetail(index) {
            this.editDetailVisible = true;
            console.log(this.orders[index]);
            this.orderIndx = index;
            let newModel = this.orders[index];
            this.Orders_Number = newModel.Orders_Number;
            this.Orders_Receiver = newModel.Orders_Receiver;
            this.Orders_Phone = newModel.Orders_Phone;
            this.Orders_Address = newModel.Orders_Address;
            this.Orders_Freight = newModel.Orders_Freight;
            this.Orders_Total = newModel.Orders_Total;
            this.Orders_Created = newModel.Orders_Created;
            this.Orders_DeliveryTime = newModel.Orders_DeliveryTime;
            this.Orders_ReceiveTime = newModel.Orders_ReceiveTime;
            this.Orders_Remark = newModel.Orders_Remark;
        },
        sureToEditDetail() {
            let newModel = this.orders[this.orderIndx];
            newModel.Orders_Number = this.Orders_Number;
            newModel.Orders_Receiver = this.Orders_Receiver;
            newModel.Orders_Phone = this.Orders_Phone;
            newModel.Orders_Address = this.Orders_Address;
            newModel.Orders_Freight = this.Orders_Freight;
            newModel.Orders_Total = this.Orders_Total;
            newModel.Orders_Created = this.Orders_Created;
            newModel.Orders_DeliveryTime = this.Orders_DeliveryTime;
            newModel.Orders_ReceiveTime = this.Orders_ReceiveTime;
            newModel.Orders_Remark = this.Orders_Remark;
            app.wpost({
               url: "/AdminAjax/MerchantOrders.ashx?type=update",
               data: {
                    model: JSON.stringify(newModel),
                },
                succ: (res) => {
                    // console.log("修改详情res:", res);
                    this.$message("修改成功！");
                    this.editDetailVisible = false;
                    this.pullList();
                }
            }
            );
        },
        deletOrder(item) {
            app.wpost({
                url:"/AdminAjax/MerchantOrders.ashx?type=delete",
                data: {
                    model: JSON.stringify(item),
                },
                succ: (res)=> {
                    this.$message("删除成功！");
                    this.pullList();
                }
            }
            );
        },
        sendStatus(statuNum, id) {
            app.wpost({
               url: "/AdminAjax/InvoiceReceive.ashx?type=updatestatus", 
               data:{
                    id: id,
                    status: statuNum,
                },
                succ: (res)=> {
                    this.$message("设置成功！");
                    this.pullList();
                }
            }
            );
        },

        switchStatus(num) {
            let statusText = "";
            // 0：全部，2：待发货，3：待收货，4：待评价，5：待分享，6：交易完成
            switch (num) {
                case 0:
                    statusText = "全部";
                    break;

                case 2:
                    statusText = "待发货";
                    break;
                case 3:
                    statusText = "待收货";
                    break;
                case 4:
                    statusText = "待评价";
                    break;
                case 5:
                    statusText = "待分享";
                    break;
                case 6:
                    statusText = "交易完成";
                    break;
            }
            return statusText;
        },
        sendDeliver() {

            if (this.deliverID == "" || this.deliverCompy == "") {
                this.$message("请填写完整快递信息内容");
                return false;
            }
            app.wpost({
                url:"/AdminAjax/MerchantOrders.ashx?type=delivery", 
                data:{
                    id: this.Orders_ID,
                    name: this.deliverCompy,
                    number: this.deliverID,
                },
                succ: (res)=> {
                    // console.log("res:", res);
                    this.$message("设置发货成功");
                    this.detailFormVisible = false;
                }
            }
            );
        },
        pullDetailsList(item) {
            this.deliverID = item.Orders_LogisticsNumber
            this.deliverCompy = item.Orders_LogisticsName
            this.Orders_ID = item.Orders_ID;
            app.wpost({
                url:"/AdminAjax/MerchantOrders.ashx?type=selectdetail", 
                data:{
                    id: item.Orders_ID,
                },
                succ: (res) =>{
                    // console.log("res:", res);
                    this.detailsList = res.list;
                    this.detailFormVisible = true;
                }
            }
            );
        },
        pullList() {
            let that = this;
            app.wpost({
               url: "/AdminAjax/MerchantOrders.ashx?type=selectmerchant",
               data: {
                    status: this.status,
                    keyword: this.keyword,
                    starttime: this.starttime,
                    endtime: this.endtime,
                    pageindex: this.pageindex,
                    productid:this.activeType,
                    pagesize: 10,
                },
                succ: (res)=> {
                    // console.log("res:", res);
                    this.orders = res.list;
                    this.total = res.count;
                }
            }
            );
        },

        pullActive(){
            
            app.wpost({
                url:"/AdminAjax/MerchantProduct.ashx?type=select",
                data: {
                    pageindex: 1,
                    pagesize: 999
                },
                 succ:(res)=> {
                    // console.log("res:", res);
                    this.activeArr = res.list;
                }
            }
            );
        
        },
        setPageIndx(pgindx) {
            // console.log(e);
            this.pageindex = pgindx;
            this.pullList();
        },
    },
};
</script>

<style>
.rows td:nth-child(10) {
    padding: 0 !important;
}
</style><style scoped>
.searchwrp {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.searchwrp label {
    display: block;
    width: 66px;
}

.imgsArr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.imgsArr img {
    height: 40px;
    margin-right: 6px;
}

.deliver-info .el-col {
    padding: 22px 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.deliver-info .el-col label {
    display: block;
    width: 100px;
}

.edit-info label {
    display: block;
    padding: 10px 0;
}

.edit-info .el-input,
.edit-info .el-select,
.edit-info .el-date-picker {
    width: 98%;
}

.el-button {
    margin: 0 6px;
}

.el-collapse-item .el-row {
    padding: 6px 12px;
}

.search-wrp .el-input {
    width: 60%;
    margin-right: 10px;
}

.el-pagination {
    margin: 16px auto;
    text-align: center;
}

.dbtns {
    padding-top: 20px;
    text-align: right;
}
</style>
